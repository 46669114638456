import * as validUrl from 'valid-url'
import axios from 'axios'
const ORD_URL = "https://ordinals-api.deezy.io"
export default async function search(satName) {
    console.log(`Searching for ${satName}`)
    const lookupUrl = `${ORD_URL}/sat/${satName}`
    let resp = await axios.get(lookupUrl).catch(err => {
        return {}
    })
    if (resp.data) {
        const ptrn = /\/inscription\/(.*?)>/g
        const inscriptionIds = []
        let match
        while ((match = ptrn.exec(resp.data)) != null) {
            inscriptionIds.push(match[1])
        }
        if (!inscriptionIds.length) {
            return { redirectUrl: lookupUrl }
        }
        // TODO: check if content is a txt file, and if so check if the txt file is a URL.
        const contentUrl = `${ORD_URL}/content/${inscriptionIds[inscriptionIds.length - 1]}`
        resp = await axios.get(contentUrl).catch(err => {
            return {}
        })
        if (resp.data) {
            const contentText = resp.data.trim()
            if (validUrl.isWebUri(contentText)) {
                return { redirectUrl: contentText }
            }
        }
        return { redirectUrl: `${ORD_URL}/content/${inscriptionIds[inscriptionIds.length - 1]}` }
    }
    return {}
}