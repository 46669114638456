import React, { useState, useRef, useEffect } from 'react';
import './App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MDBFooter } from 'mdb-react-ui-kit';
import search from './search.js'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaRegCopy } from 'react-icons/fa';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import { TailSpin } from 'react-loading-icons'
import { QRCodeSVG } from 'qrcode.react';
import Countdown from 'react-countdown';
import useInterval from 'react-useinterval';
import TitleImage from './assets/images/title.jpg'
import Table from 'react-bootstrap/Table';
import PoweredByImage from './assets/images/powered-by.png'

const axios = require('axios')

const App = () => {
  const aboutRef = useRef(null);
  const exploreRef = useRef(null);
  const scrollToRef = (ref) => ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  const existingSearch = "" // sessionStorage.getItem('lastSearch')
  const [searchEntry, setSearchEntry] = useState(existingSearch || "")
  const [isLoading, setIsLoading] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [placeholder, setPlaceholder] = useState("")
  const [invalidText, setInvalidText] = useState("")
  const [isValidSearch, setIsValidSearch] = useState(false)
  const [isShake, setShake] = useState(false)

  useEffect(() => {
    window.addEventListener('pageshow', (event) => {
      setIsLoading(false)
      if (event.persisted) {
        console.log('This page was restored from the bfcache.');
      } else {
        console.log('This page was loaded normally.');
      }
    });
  }, []);

  async function handleKeyUp(e) {
    if (e.key !== 'Enter') return
    if (searchEntry === "") return
    if (!isValidSearch) {
        setShake(true)
        setTimeout(() => setShake(false), 250)
        return
    }
    setIsLoading(true)
    const { redirectUrl } = await search(searchEntry)
    if (!redirectUrl) {
        setIsLoading(false)
        alert("No results found! Try something with 11 letters or less.")
        return
    }
    sessionStorage.setItem("lastSearch", searchEntry)
    window.location.href = redirectUrl
  }

  function isValidSearchText(text) {
    if (/^[A-Z]+$/i.test(text)) {
      // It is all letters.
      if (text.length > 11) {
        setInvalidText("Must be less than 11 letters")
        return false
      }
      return true
    }

    if (/^[0-9]+$/i.test(text)) {
      return true
    }
    if (text === 'hodl magoo') {
      setInvalidText("Sorry that person is a butt head")
      return false
    }
    setInvalidText("Must be all letters or all numbers")
    return false
  }

  function checkIsValidSearch(text) {
    setIsValidSearch(isValidSearchText(text))
  }

  return (
    <>
      <Container fluid className="pt-5 align-items-center" style={{ margin:0, height:"100vh"}}>
        <Row className="my-5 text-center">
          <Col>
            <span style={{ fontSize: "5vh"}}>Search the Decentralized Web</span>
          </Col>
        </Row>
        <Row data-bs-theme="dark" className="w-50 mb-5 justify-content-md-center"
             style={{
               maxWidth:"400px",
               margin:"auto",
               minWidth:"350px",
        }}>
          <Col className="text-center">
              { isLoading ?
                  <TailSpin className="m-auto" stroke="#ffffff" speed={.75} />
                    :
                    <InputGroup hasValidation className={isShake ? "shake" : ""} size="lg" data-bs-theme="dark">
                      <InputGroup.Text id="basic-addon1">sat/</InputGroup.Text>
                      <Form.Control
                          data-bs-theme="dark"
                          variant="secondary"
                          aria-describedby="helpBlock"
                          className="m-auto"
                          placeholder={placeholder}
                          value={searchEntry}
                          onChange={(e) => {
                            setSearchEntry(e.target.value)
                            checkIsValidSearch(e.target.value)
                          }}
                          onKeyUp={handleKeyUp}
                          isValid={isValidSearch}
                          isInvalid={searchEntry !== "" && !isValidSearch}
                      />
                      <Form.Control.Feedback type="invalid">
                        {invalidText}
                      </Form.Control.Feedback>
                    </InputGroup>
              }
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default App;
